<template>
  <SectionBasic
    id="benefit"
    :isActive="isActive">
    <h2 class="tit_section">아이들의 생활에 필요한<br>기능과 혜택들을 <span class="txt_break_response">모두 담았어요</span></h2>
    <div class="area_img">
      <div class="inner_img"></div>
    </div>
    <div class="box_benefit">
      <span class="icon_benefit icon_calendar"></span>
      <strong class="tit_benefit">정기 용돈계약</strong>
      <p class="desc_benefit">약속한 날짜에 약속한 용돈 송금</p>
      <ul class="list_benefit">
        <li>송금 수수료 전액 무료</li>
        <li>연령별 평균 용돈금액 정보 제공</li>
      </ul>
    </div>
    <div class="box_benefit">
      <span class="icon_benefit icon_transportation"></span>
      <strong class="tit_benefit">선불교통카드</strong>
      <p class="desc_benefit">전국 호환 가능</p>
    </div>
    <div class="box_benefit">
      <span class="icon_benefit icon_shop"></span>
      <strong class="tit_benefit">온라인/모바일 간편 결제</strong>
      <p class="desc_benefit">네이버페이, 페이북(ISP)에<br>퍼핀카드 등록 후 결제 가능</p>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name: 'SectionBenefit',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_benefit{background-color:#fff}
.section_benefit .desc_section{font-weight:700;font-size:24px;line-height:36px;color:#111;text-align:center}
.section_benefit .area_img{max-width:640px;margin:24px auto 0}
.section_benefit .area_img .inner_img{padding-top:62.5%;background:url(/assets/images/card/img_benefit.jpg) no-repeat 0 0;background-size:100%}

.section_benefit .box_benefit{position:relative;max-width:640px;margin:24px auto 0;padding:20px 16px 20px 76px;border-radius:8px;
background-color:#F3F1F1;box-sizing:border-box}


.section_benefit .box_benefit .icon_calendar{background-image:url(/assets/images/card/img_benefit_calendar.png)}
.section_benefit .box_benefit .icon_transportation{background-image:url(/assets/images/card/img_benefit_transportation.png)}
.section_benefit .box_benefit .icon_shop{background-image:url(/assets/images/card/img_benefit_shop.png)}

.section_benefit .box_benefit .icon_benefit{position:absolute;top:20px;left:20px;width:40px;height:40px;background-repeat:no-repeat;background-size:contain}

.section_benefit .box_benefit .tit_benefit{font-weight:700;font-size:16px;line-height:24px;color:#333}
.section_benefit .box_benefit .desc_benefit{font-weight:500;font-size:16px;line-height:24px;color:#333}
.section_benefit .box_benefit .list_benefit,
.section_benefit .box_benefit .list_benefit li{list-style:disc}
.section_benefit .box_benefit .list_benefit{margin-top:6px;padding-left:20px}
.section_benefit .box_benefit .list_benefit li{font-weight:500;font-size:14px;line-height:22px;color:#8F69DD}

</style>