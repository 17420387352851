<template>
  <SectionBasic
    id="top"
    :isActive="isActive">
    <h2 class="tit_section">은행 방문 없이<br>만들 수 있는 용돈카드</h2>
    <p class="desc_section">용돈관리와 금융교육을 한 번에!<br>주도적인 금융생활을 위해 퍼핀카드를 선물해주세요</p>
    <div class="area_img">
      <div class="inner_img"></div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name: 'SectionTop',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_comm.section_top{max-width:680px;margin:0 auto;padding:0}
.section_comm.section_top >>> .inner_section{padding-bottom:32px}
.section_comm.section_top .tit_section{font-weight:700;font-size:32px;line-height:44px;color:#111;text-align:left}
.section_comm.section_top .desc_section{margin-top:12px;padding:0;text-align:left}
.section_top .area_img{margin-top:24px}
.section_top .area_img .inner_img{padding-top:77.5%;background:url(/assets/images/card/img_top.png) no-repeat 0 0;background-size:100%;}
</style>